
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AuthenticationPasswordRecoveryChangePasswordIndex} from "@/models/authentication/password-recovery/change-password";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElContainer,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
} from "element-plus";

// Services
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElContainer,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
})
export default class AuthenticationPasswordRecoveryChangePasswordIndexVue extends Vue {
  isLoading = false;

  formData: AuthenticationPasswordRecoveryChangePasswordIndex = new AuthenticationPasswordRecoveryChangePasswordIndex();
  formDataRules = {
    verification_token: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
  };

  get translation(): any {
    return getTranslation([
      "changePassword",
      "continue",
      "password",
      "passwordPlaceholder",
      "passwordRecovery",
      "verificationToken",
      "verificationTokenPlaceholder",
      "return",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/authentication/password-recovery/change-password",
      formData: this.formData,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$router.push({
          name: "AuthenticationLoginIndexVue"
        });
      }
    });
    this.isLoading = false;
  }

  setVerificationToken(): void {
    this.formData.verification_token = this.$route.params.verification_token;
  }

  created(): void {
    this.setVerificationToken();
  }
}
