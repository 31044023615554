export interface AuthenticationPasswordRecoveryChangePasswordIndex {
  verification_token: string | string[];
  password: string;
}

export class AuthenticationPasswordRecoveryChangePasswordIndex implements AuthenticationPasswordRecoveryChangePasswordIndex {
  verification_token: string | string[];
  password: string;

  constructor(verification_token?: string | string[]) {
    this.verification_token = verification_token ?? "";
    this.password = "";
  }
}